import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "../css/privacy.css";

function Privacy() {
  return (
    <div className="main-container">
      <Header />
      <div className="complaint_box">
        <div className="complaint_div">
          <div className="complaint_div_inner">
            <h1>Privacy policy</h1>
            <div className="complaint_div_inner_p">
              <p>
                Website Design Limited (“WDL”, “us”, “we” or “our”) is committed
                to respecting your privacy and to complying with applicable data
                protection and privacy laws.
              </p>
              <p>
                You can visit our website without disclosing any personally
                identifiable information about yourself (although please note
                that we may use cookies and collect other non-personally
                identifiable information about your browsing activity – see
                our&nbsp;cookie policy&nbsp;for more information).
              </p>
              <p>
                If you do submit personal information by completing a web form,
                for example, you can be assured that we will use your personal
                information only to support your continuing relationship with
                WDL.
              </p>
              <p>
                We have provided this Privacy Policy statement to help you
                understand how we collect, use and protect your information when
                you visit our website and when you generally use our services.
              </p>
              <p>
                We wish to help you make informed decisions, so please take a
                few moments to read the sections below and learn how we may use
                your personal information.
              </p>
              <h5>Personal Information Collection</h5>
              <p>
                We endeavour to collect and use your personal information only
                with your knowledge and consent and typically when you use our
                services, make customer enquiries, register for information or
                other services, request information, submit a job application or
                when you respond to communications from us.
              </p>
              <p>
                The type of personal information we may collect could include,
                for example, your name and postal address, date of birth,
                telephone number, email address, and credit/debit card
                information.
              </p>
              <p>
                If you choose to provide us with personal information it will be
                used in support of the intended purposes stated at the time at
                which it was collected, and subject to any preferences indicated
                by you.
              </p>
              <h5>Non-personal Identifying Information</h5>
              <p>
                We may also collect non-personally identifying information about
                your visit to our website based on your browsing activities.
                This information may include the pages you browse and the
                services viewed for example. This helps us to better manage and
                develop our site, to provide you with a more enjoyable,
                customised service and experience in the future, and to help us
                develop and deliver better services tailored to your individual
                interests and needs.
              </p>
              <p>
                From time to time, if you consented accordingly we may also
                store and use your information to contact you for market
                research and marketing purposes. We may contact you by email,
                phone or mail.
              </p>
              <h5>How will we use your information?</h5>
              <p>
                We may use your information for a number of purposes which may
                include; managing and administering your account, delivering
                services, information requested by you, responding to complaints
                or account enquiries, administering debt recoveries, verifying
                your identity when required.
              </p>
              <p>
                We may also contact you with information about new developments,
                products, services and special offers by post, telephone and
                automated means such as email (subject to any preferences
                expressed by you).
              </p>
              <p>
                If you have consented to receive details of services and updates
                you can contact us at any time to have your details removed from
                our list, to update your information or to otherwise tell us how
                you would like to receive information about our and/or third
                party products and services.
              </p>
              <p>
                To update your marketing preferences please email{" "}
                <a href="mailto:syudy@medialemonade.baby">
                  syudy@medialemonade.baby
                </a>{" "}
                and tell us what you want us to do (e.g. ‘opt-out Email’).
              </p>
              <h5>When will we disclose your information to others?</h5>
              <p>
                We may only disclose information about you and contact details
                in the event that we undergo re-organisation or are sold to a
                third party, in which case you agree that any personal
                information we hold about you may be transferred to that
                re-organised entity or third party for the purposes and subject
                to the terms of this Privacy Policy Statement
              </p>
              <p>
                Please note that WDL does not sell or pass your personal
                information to third parties (other than as set out in the
                paragraph above) unless you have given us permission or unless
                it is strictly necessary to deliver the services used by you.
              </p>
              <p>
                For example, we may disclose your data to a credit card company
                to validate your credit card details and obtain payment.
              </p>
              <p>
                WDL may also be obliged to disclose your personal information to
                meet any legal or regulatory requirements (for example to comply
                with a court order) or obligations in accordance with applicable
                law.
              </p>
              <p>
                Please see below the current list of subprocessors authorised to
                process customer data for WDL services. Each subprocessor is
                GDPR compliant regarding their security controls and applicable
                regulations for the protection of personal data.
              </p>
              <ul class="blue-dots">
                <li>Google Inc</li>
                <li>Insightly</li>
                <li>UK Dedicated</li>
                <li>Receiptbank</li>
                <li>Xero</li>
                <li>Lloyds Bank</li>
                <li>Campaign Monitor</li>
                <li>Mailjet</li>
                <li>Chaser</li>
                <li>Quoteroller</li>
              </ul>
              <h5>Careers information</h5>
              <p>
                In submitting a job application you should be aware we may
                contact you to discuss if there are other opportunities or
                provide you with recommended jobs or contact you for other jobs
                you may be interested in. You can change your mind and withdraw
                such consent at any time.
              </p>
              <h5>How long do we keep your information for?</h5>
              <p>
                To make sure we meet our legal data protection and privacy
                obligations, we only hold on to your information for as long as
                we actually need it for the purposes we acquired it for in the
                first place.
              </p>
              <p>
                In most cases, this means we will keep your information for as
                long as you continue to use our services, and for a reasonable
                period of time afterwards if you stop doing so, to see if we can
                persuade you to come back to us. After that we will delete it
                other than where we lawfully can keep any data for audit or
                legal reasons.
              </p>
              <p>
                We shall keep data on our prospect database for not longer than
                3 years from receipt subject to an individual’s right to
                unsubscribe or be forgotten at any time.
              </p>
              <h5>Access to your Information</h5>
              <p>
                You can write to us at any time to obtain details of the
                personal information we may hold about you. Please write to;
                WDL, The Old Brewhouse, 75 East Hill, Colchester, Essex CO1 2QW
                or send an email to;{" "}
                <a href="mailto:syudy@medialemonade.baby">
                  syudy@medialemonade.baby
                </a>
              </p>
              <p>
                Please quote your name and address. We would be grateful if
                could also provide brief details of what information you want a
                copy of (this helps us to more readily locate your data).
              </p>
              <p>
                We will take all reasonable steps to confirm your identity
                before providing you with details of any personal information we
                may hold about you.
              </p>
              <h5>Information Security</h5>
              <p>
                WDL recognises that its customers are increasingly concerned
                about how companies protect personal information from misuse and
                abuse and about privacy in general. WDL is constantly reviewing
                and enhancing its technical, physical and managerial procedures
                and rules to protect your personal data from unauthorised
                access, accidental loss and/or destruction. For example, the WDL
                website and management portals are covered by HTTPS.
              </p>
              <p>
                Please be aware that communications over the Internet, such as
                emails, are not secure unless they have been encrypted. Your
                communications may route through a number of countries before
                being delivered – this is the nature of the Internet. WDL cannot
                accept responsibility for any unauthorised access or loss of
                personal information that is beyond our control.
              </p>
              <h5>Privacy Support</h5>
              <p>
                WDL reserves the right to amend or modify this Privacy Policy
                statement at any time and in response to changes in applicable
                data protection and privacy legislation.
              </p>
              <p>
                If we decide to change our Privacy Policy, we will post the
                changes on our website so you know what information we collect
                and how we use it. If at any point we decide to use personally
                identifiable information in a manner different from that stated
                at the time it was collected, we will tell you. You will have a
                choice as to whether or not we are able to use your information
                in this different manner.
              </p>
              <p>
                If you have any enquiry about WDL’s Privacy Policy or practices,
                please write to; WDL, 1 Thomas Court, East Street, Colchester,
                Essex CO1 2TR or send an email to;{" "}
                <a href="mailto:syudy@medialemonade.baby">
                  syudy@medialemonade.baby
                </a>
              </p>
              <h5>Monitoring and or recording of Communications</h5>
              <p>
                Monitoring or recording of your calls, emails, text messages and
                other communications may take place in accordance with UK law,
                and in particular for business purposes, such as for quality
                control and training, to prevent unauthorised use of WDL’s
                website, to ensure effective systems operation and in order to
                prevent or detect crime.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Privacy;
