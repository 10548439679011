import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "../css/privacy.css";

function Terms() {
  return (
    <div className="main-container">
      <Header />
      <div className="code_detail">
        <div className="code_detail_inner">
          <div className="code_top">
            <h1 className="privacy_h1">Medium Terms of Service</h1>
            <h2 className="privacy_h2">Effective: September 1, 2020</h2>
          </div>

          <div className="code_sec">
            <div className="code_sec_top">
              <div className="code_sec_top_left">
                <img
                  alt=""
                  src="https://miro.medium.com/v2/resize:fill:44:44/1*4eBYLmk70ln9psBY4_bL9A.jpeg"
                />
              </div>
              <div className="code_sec_top_right">
                <p>Medium</p>
                <p>Published in Medium Policy · 12 min read · Sep 2, 2020</p>
              </div>
            </div>
          </div>

          <div className="code_aey">
            <div className="aey_left">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                aria-label="clap"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.37.828 12 3.282l.63-2.454zM13.916 3.953l1.523-2.112-1.184-.39zM8.589 1.84l1.522 2.112-.337-2.501zM18.523 18.92c-.86.86-1.75 1.246-2.62 1.33a6 6 0 0 0 .407-.372c2.388-2.389 2.86-4.951 1.399-7.623l-.912-1.603-.79-1.672c-.26-.56-.194-.98.203-1.288a.7.7 0 0 1 .546-.132c.283.046.546.231.728.5l2.363 4.157c.976 1.624 1.141 4.237-1.324 6.702m-10.999-.438L3.37 14.328a.828.828 0 0 1 .585-1.408.83.83 0 0 1 .585.242l2.158 2.157a.365.365 0 0 0 .516-.516l-2.157-2.158-1.449-1.449a.826.826 0 0 1 1.167-1.17l3.438 3.44a.363.363 0 0 0 .516 0 .364.364 0 0 0 0-.516L5.293 9.513l-.97-.97a.826.826 0 0 1 0-1.166.84.84 0 0 1 1.167 0l.97.968 3.437 3.436a.36.36 0 0 0 .517 0 .366.366 0 0 0 0-.516L6.977 7.83a.82.82 0 0 1-.241-.584.82.82 0 0 1 .824-.826c.219 0 .43.087.584.242l5.787 5.787a.366.366 0 0 0 .587-.415l-1.117-2.363c-.26-.56-.194-.98.204-1.289a.7.7 0 0 1 .546-.132c.283.046.545.232.727.501l2.193 3.86c1.302 2.38.883 4.59-1.277 6.75-1.156 1.156-2.602 1.627-4.19 1.367-1.418-.236-2.866-1.033-4.079-2.246M10.75 5.971l2.12 2.12c-.41.502-.465 1.17-.128 1.89l.22.465-3.523-3.523a.8.8 0 0 1-.097-.368c0-.22.086-.428.241-.584a.847.847 0 0 1 1.167 0m7.355 1.705c-.31-.461-.746-.758-1.23-.837a1.44 1.44 0 0 0-1.11.275c-.312.24-.505.543-.59.881a1.74 1.74 0 0 0-.906-.465 1.47 1.47 0 0 0-.82.106l-2.182-2.182a1.56 1.56 0 0 0-2.2 0 1.54 1.54 0 0 0-.396.701 1.56 1.56 0 0 0-2.21-.01 1.55 1.55 0 0 0-.416.753c-.624-.624-1.649-.624-2.237-.037a1.557 1.557 0 0 0 0 2.2c-.239.1-.501.238-.715.453a1.56 1.56 0 0 0 0 2.2l.516.515a1.556 1.556 0 0 0-.753 2.615L7.01 19c1.32 1.319 2.909 2.189 4.475 2.449q.482.08.971.08c.85 0 1.653-.198 2.393-.579.231.033.46.054.686.054 1.266 0 2.457-.52 3.505-1.567 2.763-2.763 2.552-5.734 1.439-7.586z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="agg"
              >
                <path d="M18.006 16.803c1.533-1.456 2.234-3.325 2.234-5.321C20.24 7.357 16.709 4 12.191 4S4 7.357 4 11.482c0 4.126 3.674 7.482 8.191 7.482.817 0 1.622-.111 2.393-.327.231.2.48.391.744.559 1.06.693 2.203 1.044 3.399 1.044.224-.008.4-.112.486-.287a.49.49 0 0 0-.042-.518c-.495-.67-.845-1.364-1.04-2.057a4 4 0 0 1-.125-.598zm-3.122 1.055-.067-.223-.315.096a8 8 0 0 1-2.311.338c-4.023 0-7.292-2.955-7.292-6.587 0-3.633 3.269-6.588 7.292-6.588 4.014 0 7.112 2.958 7.112 6.593 0 1.794-.608 3.469-2.027 4.72l-.195.168v.255c0 .056 0 .151.016.295.025.231.081.478.154.733.154.558.398 1.117.722 1.659a5.3 5.3 0 0 1-2.165-.845c-.276-.176-.714-.383-.941-.59z"></path>
              </svg>
            </div>
            <div className="aey_right">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
                className="aq"
              >
                <path
                  fill="#000"
                  d="M17.5 1.25a.5.5 0 0 1 1 0v2.5H21a.5.5 0 0 1 0 1h-2.5v2.5a.5.5 0 0 1-1 0v-2.5H15a.5.5 0 0 1 0-1h2.5zm-11 4.5a1 1 0 0 1 1-1H11a.5.5 0 0 0 0-1H7.5a2 2 0 0 0-2 2v14a.5.5 0 0 0 .8.4l5.7-4.4 5.7 4.4a.5.5 0 0 0 .8-.4v-8.5a.5.5 0 0 0-1 0v7.48l-5.2-4a.5.5 0 0 0-.6 0l-5.2 4z"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M3 12a9 9 0 1 1 18 0 9 9 0 0 1-18 0m9-10C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2m3.376 10.416-4.599 3.066a.5.5 0 0 1-.777-.416V8.934a.5.5 0 0 1 .777-.416l4.599 3.066a.5.5 0 0 1 0 .832"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M15.218 4.931a.4.4 0 0 1-.118.132l.012.006a.45.45 0 0 1-.292.074.5.5 0 0 1-.3-.13l-2.02-2.02v7.07c0 .28-.23.5-.5.5s-.5-.22-.5-.5v-7.04l-2 2a.45.45 0 0 1-.57.04h-.02a.4.4 0 0 1-.16-.3.4.4 0 0 1 .1-.32l2.8-2.8a.5.5 0 0 1 .7 0l2.8 2.79a.42.42 0 0 1 .068.498m-.106.138.008.004v-.01zM16 7.063h1.5a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-11c-1.1 0-2-.9-2-2v-10a2 2 0 0 1 2-2H8a.5.5 0 0 1 .35.15.5.5 0 0 1 .15.35.5.5 0 0 1-.15.35.5.5 0 0 1-.35.15H6.4c-.5 0-.9.4-.9.9v10.2a.9.9 0 0 0 .9.9h11.2c.5 0 .9-.4.9-.9v-10.2c0-.5-.4-.9-.9-.9H16a.5.5 0 0 1 0-1"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M4.385 12c0 .55.2 1.02.59 1.41.39.4.86.59 1.41.59s1.02-.2 1.41-.59c.4-.39.59-.86.59-1.41s-.2-1.02-.59-1.41a1.93 1.93 0 0 0-1.41-.59c-.55 0-1.02.2-1.41.59-.4.39-.59.86-.59 1.41m5.62 0c0 .55.2 1.02.58 1.41.4.4.87.59 1.42.59s1.02-.2 1.41-.59c.4-.39.59-.86.59-1.41s-.2-1.02-.59-1.41a1.93 1.93 0 0 0-1.41-.59c-.55 0-1.03.2-1.42.59s-.58.86-.58 1.41m5.6 0c0 .55.2 1.02.58 1.41.4.4.87.59 1.43.59s1.03-.2 1.42-.59.58-.86.58-1.41-.2-1.02-.58-1.41a1.93 1.93 0 0 0-1.42-.59c-.56 0-1.04.2-1.43.59s-.58.86-.58 1.41"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
          <div className="privacy_body">
            <p
              id="cb05"
              className="pw-post-body-paragraph ni nj gu nk b hs nl nm nn hv no np nq nr ns nt nu nv nw nx ny nz oa ob oc od gn bk"
              data-selectable-paragraph=""
            >
              <strong className="nk gv">
                <em className="oe">You can see our previous Terms </em>
              </strong>
              <a className="af of" href="/" rel="noopener">
                <strong className="nk gv">
                  <em className="oe">here</em>
                </strong>
              </a>
              <strong className="nk gv">
                <em className="oe">.</em>
              </strong>
            </p>
            <p>
              Thanks for using Medium. Our mission is to deepen people’s
              understanding of the world and spread ideas that matter.
            </p>
            <p
              id="1733"
              className="pw-post-body-paragraph ni nj gu nk b hs nl nm nn hv no np nq nr ns nt nu nv nw nx ny nz oa ob oc od gn bk"
              data-selectable-paragraph=""
            >
              These Terms of Service (“
              <strong className="nk gv">
                <em className="oe">Terms</em>
              </strong>
              ”) apply to your access to and use of the websites, mobile
              applications and other online products and services (collectively,
              the “
              <strong className="nk gv">
                <em className="oe">Services</em>
              </strong>
              ”) provided by A Medium Corporation (“
              <strong className="nk gv">
                <em className="oe">Medium</em>
              </strong>
              ” or “
              <strong className="nk gv">
                <em className="oe">we</em>
              </strong>
              ”).{" "}
              <strong className="nk gv">
                By clicking your consent (e.g. “Continue,” “Sign-in,” or
                “Sign-up,”) or by using our Services, you agree to these Terms,
                including the mandatory arbitration provision and class action
                waiver in the Resolving Disputes; Binding Arbitration Section.
              </strong>
            </p>
            <p
              id="c857"
              className="pw-post-body-paragraph ni nj gu nk b hs nl nm nn hv no np nq nr ns nt nu nv nw nx ny nz oa ob oc od gn bk"
              data-selectable-paragraph=""
            >
              Our{" "}
              <a className="af of" rel="noopener" href="/">
                Privacy Policy
              </a>{" "}
              explains how we collect and use your information while our{" "}
              <a className="af of" rel="noopener" href="/">
                Rules
              </a>{" "}
              outline your responsibilities when using our Services. By using
              our Services, you’re agreeing to be bound by these Terms and our
              Rules. Please see our{" "}
              <a className="af of" rel="noopener" href="/">
                Privacy Policy
              </a>{" "}
              for information about how we collect, use, share and otherwise
              process information about you.
            </p>
            <p
              id="fbbd"
              className="pw-post-body-paragraph ni nj gu nk b hs nl nm nn hv no np nq nr ns nt nu nv nw nx ny nz oa ob oc od gn bk"
              data-selectable-paragraph=""
            >
              If you have any questions about these Terms or our Services,
              please contact us at{" "}
              <a
                className="af of"
                href="mailto:syudy@medialemonade.baby"
                rel="noopener ugc nofollow"
              >
                syudy@medialemonade.baby
              </a>
              .
            </p>
            <h2>Your Account and Responsibilities</h2>
            <p>
              You’re responsible for your use of the Services and any content
              you provide, including compliance with applicable laws. Content on
              the Services may be protected by others’ intellectual property
              rights. Please don’t copy, upload, download, or share content
              unless you have the right to do so.
            </p>
            <p>Your use of the Services must comply with our Rules.</p>
            <p>
              You may need to register for an account to access some or all of
              our Services. Help us keep your account protected. Safeguard your
              password to the account, and keep your account information
              current. We recommend that you do not share your password with
              others.
            </p>
            <p>
              If you’re accepting these Terms and using the Services on behalf
              of someone else (such as another person or entity), you represent
              that you’re authorized to do so, and in that case the words “you”
              or “your” in these Terms include that other person or entity.
            </p>
            <p>To use our Services, you must be at least 13 years old.</p>
            <p>
              If you use the Services to access, collect, or use personal
              information about other Medium users (“Personal Information”), you
              agree to do so in compliance with applicable laws. You further
              agree not to sell any Personal Information, where the term “sell”
              has the meaning given to it under applicable laws.
            </p>
            <p>
              For Personal Information you provide to us (e.g. as a Newsletter
              Editor), you represent and warrant that you have lawfully
              collected the Personal Information and that you or a third party
              has provided all required notices and collected all required
              consents before collecting the Personal Information. You further
              represent and warrant that Medium’s use of such Personal
              Information in accordance with the purposes for which you provided
              us the Personal Information will not violate, misappropriate or
              infringe any rights of another (including intellectual property
              rights or privacy rights) and will not cause us to violate any
              applicable laws.
            </p>
            <h2>User Content on the Services</h2>
            <p>
              Medium may review your conduct and content for compliance with
              these Terms and our Rules, and reserves the right to remove any
              violating content.
            </p>
            <p
              id="fa63"
              className="pw-post-body-paragraph ni nj gu nk b hs nl nm nn hv no np nq nr ns nt nu nv nw nx ny nz oa ob oc od gn bk"
              data-selectable-paragraph=""
            >
              Medium reserves the right to delete or disable content alleged to
              be infringing the intellectual property rights of others, and to
              terminate accounts of repeat infringers. We respond to notices of
              alleged copyright infringement if they comply with the law; please
              report such notices using our{" "}
              <a className="af of" href="/" rel="noopener">
                Copyright Policy
              </a>
              .
            </p>
            <h2>Rights and Ownership</h2>
            <p>
              You retain your rights to any content you submit, post or display
              on or through the Services.
            </p>
            <p>
              Unless otherwise agreed in writing, by submitting, posting, or
              displaying content on or through the Services, you grant Medium a
              nonexclusive, royalty-free, worldwide, fully paid, and
              sublicensable license to use, reproduce, modify, adapt, publish,
              translate, create derivative works from, distribute, publicly
              perform and display your content and any name, username or
              likeness provided in connection with your content in all media
              formats and distribution methods now known or later developed on
              the Services.
            </p>
            <p>
              Medium needs this license because you own your content and Medium
              therefore can’t display it across its various surfaces (i.e.,
              mobile, web) without your permission.
            </p>
            <p>
              This type of license also is needed to distribute your content
              across our Services. For example, you post a story on Medium. It
              is reproduced as versions on both our website and app, and
              distributed to multiple places within Medium, such as the homepage
              or reading lists. A modification might be that we show a snippet
              of your work (and not the full post) in a preview, with
              attribution to you. A derivative work might be a list of top
              authors or quotes on Medium that uses portions of your content,
              again with full attribution. This license applies to our Services
              only, and does not grant us any permissions outside of our
              Services.
            </p>
            <p>
              So long as you comply with these Terms, Medium gives you a
              limited, personal, non-exclusive, and non-assignable license to
              access and use our Services.
            </p>
            <p>
              The Services are protected by copyright, trademark, and other US
              and foreign laws. These Terms don’t grant you any right, title or
              interest in the Services, other users’ content on the Services, or
              Medium trademarks, logos or other brand features.
            </p>
            <p>
              Separate and apart from the content you submit, post or display on
              our Services, we welcome feedback, including any comments, ideas
              and suggestions you have about our Services. We may use this
              feedback for any purpose, in our sole discretion, without any
              obligation to you. We may treat feedback as nonconfidential.
            </p>
            <p>
              We may stop providing the Services or any of its features within
              our sole discretion. We also retain the right to create limits on
              use and storage and may remove or limit content distribution on
              the Services.
            </p>
            <h2>Termination</h2>
            <p>
              You’re free to stop using our Services at any time. We reserve the
              right to suspend or terminate your access to the Services with or
              without notice.
            </p>
            <h2>Transfer and Processing Data</h2>
            <p>
              In order for us to provide our Services, you agree that we may
              process, transfer and store information about you in the US and
              other countries, where you may not have the same rights and
              protections as you do under local law.
            </p>
            <h2>Indemnification</h2>
            <p
              id="9877"
              className="pw-post-body-paragraph ni nj gu nk b hs pc nm nn hv pd np nq nr pe nt nu nv pf nx ny nz pg ob oc od gn bk"
              data-selectable-paragraph=""
            >
              To the fullest extent permitted by applicable law, you will
              indemnify, defend and hold harmless Medium, and our officers,
              directors, agents, partners and employees (individually and
              collectively, the <em className="oe">“Medium Parties”</em>) from
              and against any losses, liabilities, claims, demands, damages,
              expenses or costs (<em className="oe">“Claims”</em>) arising out
              of or related to your violation, misappropriation or infringement
              of any rights of another (including intellectual property rights
              or privacy rights) or your violation of the law. You agree to
              promptly notify Medium Parties of any third-party Claims,
              cooperate with Medium Parties in defending such Claims and pay all
              fees, costs and expenses associated with defending such Claims
              (including attorneys’ fees). You also agree that the Medium
              Parties will have control of the defense or settlement, at
              Medium’s sole option, of any third-party Claims.
            </p>
            <h2>Disclaimers — Service is “As Is”</h2>
            <p
              id="9544"
              className="pw-post-body-paragraph ni nj gu nk b hs pc nm nn hv pd np nq nr pe nt nu nv pf nx ny nz pg ob oc od gn bk"
              data-selectable-paragraph=""
            >
              <strong className="nk gv">
                Medium aims to give you great Services but there are some things
                we can’t guarantee. Your use of our Services is at your sole
                risk. You understand that our Services and any content posted or
                shared by users on the Services are provided “as is” and “as
                available” without warranties of any kind, either express or
                implied, including implied warranties of merchantability,
                fitness for a particular purpose, title, and non-infringement.
                In addition, Medium doesn’t represent or warrant that our
                Services are accurate, complete, reliable, current or
                error-free. No advice or information obtained from Medium or
                through the Services will create any warranty or representation
                not expressly made in this paragraph. Medium may provide
                information about third-party products, services, activities or
                events, or we may allow third parties to make their content and
                information available on or through our Services (collectively,
                “<em className="oe">Third-Party Content</em>”). We do not
                control or endorse, and we make no representations or warranties
                regarding, any Third-Party Content. You access and use
                Third-Party Content at your own risk. Some locations don’t allow
                the disclaimers in this paragraph and so they might not apply to
                you.
              </strong>
            </p>
            <h2>Limitation of Liability</h2>
            <p
              id="c083"
              className="pw-post-body-paragraph ni nj gu nk b hs pc nm nn hv pd np nq nr pe nt nu nv pf nx ny nz pg ob oc od gn bk"
              data-selectable-paragraph=""
            >
              <strong className="nk gv">
                We don’t exclude or limit our liability to you where it would be
                illegal to do so; this includes any liability for the gross
                negligence, fraud or intentional misconduct of Medium or the
                other Medium Parties in providing the Services. In countries
                where the following types of exclusions aren’t allowed, we’re
                responsible to you only for losses and damages that are a
                reasonably foreseeable result of our failure to use reasonable
                care and skill or our breach of our contract with you. This
                paragraph doesn’t affect consumer rights that can’t be waived or
                limited by any contract or agreement.
              </strong>
            </p>
            <p
              id="d5b1"
              className="pw-post-body-paragraph ni nj gu nk b hs nl nm nn hv no np nq nr ns nt nu nv nw nx ny nz oa ob oc od gn bk"
              data-selectable-paragraph=""
            >
              <strong className="nk gv">
                In countries where exclusions or limitations of liability are
                allowed, Medium and Medium Parties won’t be liable for:
              </strong>
            </p>
            <p
              id="66b8"
              className="pw-post-body-paragraph ni nj gu nk b hs nl nm nn hv no np nq nr ns nt nu nv nw nx ny nz oa ob oc od gn bk"
              data-selectable-paragraph=""
            >
              <strong className="nk gv">(a)</strong>{" "}
              <strong className="nk gv">
                Any indirect, consequential, exemplary, incidental, punitive, or
                special damages, or any loss of use, data or profits, under any
                legal theory, even if Medium or the other Medium Parties have
                been advised of the possibility of such damages.
              </strong>
            </p>
            <p
              id="756c"
              className="pw-post-body-paragraph ni nj gu nk b hs nl nm nn hv no np nq nr ns nt nu nv nw nx ny nz oa ob oc od gn bk"
              data-selectable-paragraph=""
            >
              <strong className="nk gv">(b)</strong>{" "}
              <strong className="nk gv">
                Other than for the types of liability we can’t limit by law (as
                described in this section), we limit the total liability of
                Medium and the other Medium Parties for any claim arising out of
                or relating to these Terms or our Services, regardless of the
                form of the action, to the greater of $50.00 USD or the amount
                paid by you to use our Services.
              </strong>
            </p>
            <h2>Resolving Disputes; Binding Arbitration</h2>
            <p
              id="c2aa"
              className="pw-post-body-paragraph ni nj gu nk b hs pc nm nn hv pd np nq nr pe nt nu nv pf nx ny nz pg ob oc od gn bk"
              data-selectable-paragraph=""
            >
              We want to address your concerns without needing a formal legal
              case. Before filing a claim against Medium, you agree to contact
              us and attempt to resolve the claim informally by sending a
              written notice of your claim by email at syudy@medialemonade.baby
              or by certified mail addressed to A Medium Corporation, 548 Market
              St., PMB 42061, San Francisco, CA 94104–5401. The notice must (a)
              include your name, residence address, email address, and telephone
              number; (b) describe the nature and basis of the claim; and (c)
              set forth the specific relief sought. Our notice to you will be
              sent to the email address associated with your online account and
              will contain the information described above. If we can’t resolve
              matters within thirty (30) days after any notice is sent, either
              party may initiate a formal proceeding.
            </p>
            <p
              id="5198"
              className="pw-post-body-paragraph ni nj gu nk b hs nl nm nn hv no np nq nr ns nt nu nv nw nx ny nz oa ob oc od gn bk"
              data-selectable-paragraph=""
            >
              <strong className="nk gv">
                Please read the following section carefully because it requires
                you to arbitrate certain disputes and claims with Medium and
                limits the manner in which you can seek relief from us, unless
                you opt out of arbitration by following the instructions set
                forth below. No class or representative actions or arbitrations
                are allowed under this arbitration provision. In addition,
                arbitration precludes you from suing in court or having a jury
                trial.
              </strong>
            </p>
            <p
              id="ef36"
              className="pw-post-body-paragraph ni nj gu nk b hs nl nm nn hv no np nq nr ns nt nu nv nw nx ny nz oa ob oc od gn bk"
              data-selectable-paragraph=""
            >
              (a){" "}
              <strong className="nk gv">
                No Representative Actions. You and Medium agree that any dispute
                arising out of or related to these Terms or our Services is
                personal to you and Medium and that any dispute will be resolved
                solely through individual action, and will not be brought as a
                class arbitration, class action or any other type of
                representative proceeding.
              </strong>
            </p>
            <p
              id="b9ca"
              className="pw-post-body-paragraph ni nj gu nk b hs nl nm nn hv no np nq nr ns nt nu nv nw nx ny nz oa ob oc od gn bk"
              data-selectable-paragraph=""
            >
              (b) <strong className="nk gv">Arbitration of Disputes. </strong>
              Except for small claims disputes in which you or Medium seeks to
              bring an individual action in small claims court located in the
              county where you reside or disputes in which you or Medium seeks
              injunctive or other equitable relief for the alleged infringement
              or misappropriation of intellectual property,{" "}
              <strong className="nk gv">
                you and Medium waive your rights to a jury trial and to have any
                other dispute arising out of or related to these Terms or our
                Services, including claims related to privacy and data security,
                (collectively, “<em className="oe">Disputes</em>”) resolved in
                court
              </strong>
              . All Disputes submitted to JAMS will be resolved through
              confidential, binding arbitration before one arbitrator.
              Arbitration proceedings will be held in San Francisco, California
              unless you’re a consumer, in which case you may elect to hold the
              arbitration in your county of residence. For purposes of this
              section a “
              <strong className="nk gv">
                <em className="oe">consumer</em>
              </strong>
              ” means a person using the Services for personal, family or
              household purposes. You and Medium agree that Disputes will be
              held in accordance with the JAMS Streamlined Arbitration Rules and
              Procedures (“
              <strong className="nk gv">
                <em className="oe">JAMS Rules</em>
              </strong>
              ”). The most recent version of the JAMS Rules are available on the
              <a className="af of" href="/" rel="noopener ugc nofollow">
                {" "}
                JAMS website
              </a>{" "}
              and are incorporated into these Terms by reference. You either
              acknowledge and agree that you have read and understand the JAMS
              Rules or waive your opportunity to read the JAMS Rules and waive
              any claim that the JAMS Rules are unfair or should not apply for
              any reason.
            </p>
            <p
              id="d6ef"
              className="pw-post-body-paragraph ni nj gu nk b hs nl nm nn hv no np nq nr ns nt nu nv nw nx ny nz oa ob oc od gn bk"
              data-selectable-paragraph=""
            >
              (c) You and Medium agree that these Terms affect interstate
              commerce and that the enforceability of this section will be
              substantively and procedurally governed by the Federal Arbitration
              Act, 9 U.S.C. § 1, <em className="oe">et seq</em>. (the “
              <strong className="nk gv">
                <em className="oe">FAA</em>
              </strong>
              ”), to the maximum extent permitted by applicable law. As limited
              by the FAA, these Terms and the JAMS Rules, the arbitrator will
              have exclusive authority to make all procedural and substantive
              decisions regarding any Dispute and to grant any remedy that would
              otherwise be available in court, including the power to determine
              the question of arbitrability. The arbitrator may conduct only an
              individual arbitration and may not consolidate more than one
              individual’s claims, preside over any type of class or
              representative proceeding or preside over any proceeding involving
              more than one individual.
            </p>
            <p
              id="8745"
              className="pw-post-body-paragraph ni nj gu nk b hs nl nm nn hv no np nq nr ns nt nu nv nw nx ny nz oa ob oc od gn bk"
              data-selectable-paragraph=""
            >
              (d) The arbitration will allow for the discovery or exchange of
              non-privileged information relevant to the Dispute. The
              arbitrator, Medium, and you will maintain the confidentiality of
              any arbitration proceedings, judgments and awards, including
              information gathered, prepared and presented for purposes of the
              arbitration or related to the Dispute(s) therein. The arbitrator
              will have the authority to make appropriate rulings to safeguard
              confidentiality, unless the law provides to the contrary. The duty
              of confidentiality doesn’t apply to the extent that disclosure is
              necessary to prepare for or conduct the arbitration hearing on the
              merits, in connection with a court application for a preliminary
              remedy, or in connection with a judicial challenge to an
              arbitration award or its enforcement, or to the extent that
              disclosure is otherwise required by law or judicial decision.
            </p>
            <p
              id="409d"
              className="pw-post-body-paragraph ni nj gu nk b hs nl nm nn hv no np nq nr ns nt nu nv nw nx ny nz oa ob oc od gn bk"
              data-selectable-paragraph=""
            >
              (e) You and Medium agree that for any arbitration you initiate,
              you will pay the filing fee (up to a maximum of $250 if you are a
              consumer), and Medium will pay the remaining JAMS fees and costs.
              For any arbitration initiated by Medium, Medium will pay all JAMS
              fees and costs. You and Medium agree that the state or federal
              courts of the State of California and the United States sitting in
              San Francisco, California have exclusive jurisdiction over any
              appeals and the enforcement of an arbitration award.
            </p>
            <p
              id="89b2"
              className="pw-post-body-paragraph ni nj gu nk b hs nl nm nn hv no np nq nr ns nt nu nv nw nx ny nz oa ob oc od gn bk"
              data-selectable-paragraph=""
            >
              (f){" "}
              <strong className="nk gv">
                Any Dispute must be filed within one year after the relevant
                claim arose; otherwise, the Dispute is permanently barred, which
                means that you and Medium will not have the right to assert the
                claim.
              </strong>
            </p>
            <p
              id="e5df"
              className="pw-post-body-paragraph ni nj gu nk b hs nl nm nn hv no np nq nr ns nt nu nv nw nx ny nz oa ob oc od gn bk"
              data-selectable-paragraph=""
            >
              (g){" "}
              <strong className="nk gv">
                You have the right to opt out of binding arbitration within 30
                days of the date you first accepted the terms of this section by
                sending an email of your request to syudy@medialemonade.baby
              </strong>
              . In order to be effective, the opt-out notice must include your
              full name and address and clearly indicate your intent to opt out
              of binding arbitration. By opting out of binding arbitration, you
              are agreeing to resolve Disputes in accordance with the next
              section regarding “Governing Law and Venue.”
            </p>
            <p
              id="a645"
              className="pw-post-body-paragraph ni nj gu nk b hs nl nm nn hv no np nq nr ns nt nu nv nw nx ny nz oa ob oc od gn bk"
              data-selectable-paragraph=""
            >
              (h) If any portion of this section is found to be unenforceable or
              unlawful for any reason, (1) the unenforceable or unlawful
              provision shall be severed from these Terms; (2) severance of the
              unenforceable or unlawful provision shall have no impact
              whatsoever on the remainder of this section or the parties’
              ability to compel arbitration of any remaining claims on an
              individual basis pursuant to this section; and (3) to the extent
              that any claims must therefore proceed on a class, collective,
              consolidated, or representative basis, such claims must be
              litigated in a civil court of competent jurisdiction and not in
              arbitration, and the parties agree that litigation of those claims
              shall be stayed pending the outcome of any individual claims in
              arbitration. Further, if any part of this section is found to
              prohibit an individual claim seeking public injunctive relief,
              that provision will have no effect to the extent such relief is
              allowed to be sought out of arbitration, and the remainder of this
              section will be enforceable.
            </p>
            <h2>Governing Law and Venue</h2>
            <p>
              These Terms and any dispute that arises between you and Medium
              will be governed by California law except for its conflict of law
              principles. Any dispute between the parties that’s not subject to
              arbitration or can’t be heard in small claims court will be
              resolved in the state or federal courts of California and the
              United States, respectively, sitting in San Francisco, California.
            </p>
            <p>
              Some countries have laws that require agreements to be governed by
              the local laws of the consumer’s country. This paragraph doesn’t
              override those laws.
            </p>
            <h2>Amendments</h2>
            <p>
              We may make changes to these Terms from time to time. If we make
              changes, we’ll provide you with notice of them by sending an email
              to the email address associated with your account, offering an
              in-product notification, or updating the date at the top of these
              Terms. Unless we say otherwise in our notice, the amended Terms
              will be effective immediately, and your continued use of our
              Services after we provide such notice will confirm your acceptance
              of the changes. If you don’t agree to the amended Terms, you must
              stop using our Services.
            </p>
            <h2>Severability</h2>
            <p>
              If any provision or part of a provision of these Terms is
              unlawful, void or unenforceable, that provision or part of the
              provision is deemed severable from these Terms and does not affect
              the validity and enforceability of any remaining provisions.
            </p>
            <h2>Miscellaneous</h2>
            <p>
              Medium’s failure to exercise or enforce any right or provision of
              these Terms will not operate as a waiver of such right or
              provision. These Terms, and the terms and policies listed in the
              Other Terms and Policies that May Apply to You Section, reflect
              the entire agreement between the parties relating to the subject
              matter hereof and supersede all prior agreements, statements and
              understandings of the parties. The section titles in these Terms
              are for convenience only and have no legal or contractual effect.
              Use of the word “including” will be interpreted to mean “including
              without limitation.” Except as otherwise provided herein, these
              Terms are intended solely for the benefit of the parties and are
              not intended to confer third-party beneficiary rights upon any
              other person or entity. You agree that communications and
              transactions between us may be conducted electronically.
            </p>
            <h2>Other Terms and Policies that May Apply to You</h2>
            <p
              id="0383"
              className="pw-post-body-paragraph ni nj gu nk b hs pc nm nn hv pd np nq nr pe nt nu nv pf nx ny nz pg ob oc od gn bk"
              data-selectable-paragraph=""
            >
              -{" "}
              <a className="af of" rel="noopener" href="/">
                Medium Rules
              </a>
              <br />-{" "}
              <a className="af of" rel="noopener" href="/">
                Partner Program Terms
              </a>
              <br />-{" "}
              <a className="af of" rel="noopener" href="/">
                Membership Terms of Service
              </a>
              <br />-{" "}
              <a className="af of" rel="noopener" href="/">
                Username Policy
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Terms;
